import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { StatusBadge } from 'components/StatusBadge';
import { ContainerType, OptimizationTask, OptimizationTaskStatus } from 'types';
import { RemoveOptimizationTaskDialog } from './components/RemoveOptimizationTaskDialog';
import './OptimizationTasksBoard.css';

type Props = {
    containerTypes: ContainerType[];
    optimizationTasks: OptimizationTask[];
};

export const OptimizationTasksBoard = ({ containerTypes, optimizationTasks }: Props) => {
    const containerTypesMap = new Map<string, string>();
    const navigate = useNavigate();
    const [optimizationTaskToRemove, setOptimizationTaskToRemove] = useState<OptimizationTask | null>(null);
    const [removeOptimizationTaskDialogAnchor, setRemoveOptimizationTaskDialogAnchor] = useState<Element | null>(null);

    for (const containerType of containerTypes) containerTypesMap.set(containerType.id, containerType.name);

    const table = useReactTable({
        data: optimizationTasks,
        columns: [
            {
                accessorFn: (x) => x.projectName,
                id: 'projectName',
                header: 'Project name',
            },
            {
                accessorFn: (x) => containerTypesMap.get(x.containerTypeId)!,
                id: 'containerType',
                header: 'Container type',
            },
            {
                accessorFn: (x) => x.panelsCount,
                id: 'panelsCount',
                header: 'Panels count',
            },
            {
                accessorFn: (x) => x.maxBoxesMoves,
                id: 'maxBoxesMoves',
                header: 'Max boxes moved',
            },
            {
                accessorFn: (x) => x.status,
                id: 'status',
                header: 'Status',
                cell: (x) => <StatusBadge status={x.getValue<OptimizationTaskStatus>()} />,
            },
            {
                accessorFn: (x) => x.containersCount,
                id: 'containersCount',
                header: 'Containers count',
            },
            {
                accessorFn: (x) => x.createdAt,
                id: 'createdAt',
                header: 'Created',
            },
        ],
        getCoreRowModel: getCoreRowModel(),
    });

    const onRemoveOptimizationTask = (event: React.MouseEvent, optimizationTask: OptimizationTask) => {
        event.preventDefault();
        event.stopPropagation();

        const anchor = document.querySelector(`[data-row-optimization-task-id="${optimizationTask.id}"]`);

        if (!anchor) return;

        setOptimizationTaskToRemove(optimizationTask);
        setRemoveOptimizationTaskDialogAnchor(anchor);
    };

    const onOptimizationTaskRemoveDialogClose = () => {
        setOptimizationTaskToRemove(null);
        setRemoveOptimizationTaskDialogAnchor(null);
    };

    return (
        <>
            <TableContainer className='optimization-tasks-table'>
                <Box className='optimization-tasks-table__table-wrapper'>
                    <MuiTable stickyHeader>
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <TableCell
                                                key={header.id}
                                                className='optimization-tasks-table__header-cell'
                                                colSpan={header.colSpan}
                                            >
                                                <div className='optimization-tasks-table__header-cell-wrapper'>
                                                    <div className='optimization-tasks-table__header-column-name-wrapper'>
                                                        <Typography
                                                            className='optimization-tasks-table__header-column-name'
                                                            variant='body1'
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext(),
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell
                                        key={`${headerGroup.id}-controls`}
                                        className='optimization-tasks-table__header-cell'
                                    ></TableCell>
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => {
                                return (
                                    <TableRow
                                        key={row.id}
                                        onClick={() => navigate(`/optimization-tasks/${row.original.id}`)}
                                        className='optimization-tasks-table__row'
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <TableCell key={cell.id} className='optimization-tasks-table__row-cell'>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell key={`${row.id}-remove`}>
                                            <IconButton
                                                onClick={(event) => onRemoveOptimizationTask(event, row.original)}
                                                aria-label='Remove optimization task'
                                                size='small'
                                            >
                                                <DeleteOutlineOutlinedIcon
                                                    data-row-optimization-task-id={row.original.id}
                                                />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </MuiTable>
                </Box>
            </TableContainer>

            {optimizationTaskToRemove && removeOptimizationTaskDialogAnchor && (
                <RemoveOptimizationTaskDialog
                    onClose={onOptimizationTaskRemoveDialogClose}
                    anchor={removeOptimizationTaskDialogAnchor}
                    optimizationTask={optimizationTaskToRemove}
                />
            )}
        </>
    );
};
