import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_PATHS } from 'constants/apiPaths';
import { BasicItemsResponse, ContainerType } from 'types';
import { useAuth } from 'utils/useAuth';

type ResponseType = BasicItemsResponse<ContainerType>;

export const useContainerTypes = (select?: (data: ResponseType) => ResponseType) => {
    const { token } = useAuth();

    return useQuery<ResponseType>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['container-types'],
        queryFn: async () => {
            const response = await axios.get<ResponseType>(API_PATHS.containerTypes, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        },
        enabled: !!token,
        select: select,
    });
};
