import { Box } from '@mui/material';
import { ErrorContainer } from 'components/ErrorContainer';
import { useOptimizationTask } from 'queries/optimizationTaskQuery';
import { OptimizationTaskDetails } from './components/OptimizationTaskDetails';
import { OptimizationTaskPanelsList } from './components/OptimizationTaskPanelsList';
import './OptimizationTaskPage.css';
import '../RootPage/RootPage.css';

export const OptimizationTaskPage = () => {
    const { data, error, isSuccess, isLoading } = useOptimizationTask();

    const failedToLoad = !isLoading && (!isSuccess || !data.isSuccess || data.item === null);
    const wrapperClassName = failedToLoad
        ? 'root-page__optimization-tasks-wrapper failure'
        : 'root-page__optimization-tasks-wrapper';

    return (
        <Box className='root-page'>
            <Box className={wrapperClassName}>
                {!failedToLoad ? (
                    <Box className='optimization-task-layout-wrapper'>
                        <OptimizationTaskDetails isLoading={isLoading} optimizationTask={data?.item} />
                        <OptimizationTaskPanelsList isLoading={isLoading} optimizationTask={data?.item} />
                    </Box>
                ) : (
                    <ErrorContainer data={data} error={error} />
                )}
            </Box>
        </Box>
    );
};
