import { createTheme } from '@mui/material/styles';

const shadowKeyUmbraOpacity = '20%';
const shadowKeyPenumbraOpacity = '14%';
const shadowAmbientShadowOpacity = '12%';

function createShadow(...px: number[]) {
    return [
        `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px color-mix(in srgb, var(--main) ${shadowKeyUmbraOpacity}, transparent)`,
        `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px color-mix(in srgb, var(--main) ${shadowKeyPenumbraOpacity}, transparent)`,
        `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px color-mix(in srgb, var(--main) ${shadowAmbientShadowOpacity}, transparent)`,
    ].join(',');
}
export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#304e49',
        },
        secondary: {
            main: '#4e706a',
        },
        text: {
            primary: '#304e49',
            secondary: '#4e706a',
        },
        error: {
            main: '#e73737',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
        h1: {
            fontSize: '2.5rem',
        },
        h2: {
            fontSize: '2rem',
        },
        h3: {
            fontSize: '1.8rem',
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1.2rem',
        },
        h6: {
            fontSize: '1rem',
        },
    },
    shadows: [
        'none',
        createShadow(0, 1, 3, 0, 0, 1, 1, 0, 0, 2, 1, -1),
        createShadow(0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2),
        createShadow(0, 1, 8, 0, 0, 3, 4, 0, 0, 3, 3, -2),
        createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
        createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
        createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
        createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
        createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
        createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
        createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
        createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
        createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
        createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
        createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
        createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
        createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
        createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
        createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
        createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
        createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
        createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
        createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
        createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
        createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
    ],
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    height: '4rem',
                    zIndex: 0,
                    backgroundColor: '#ffffff',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Preserve button text case
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: 'linear-gradient(180deg, var(--bg) 0%, var(--bg) 50%, var(--white) 100%)',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    backgroundColor: 'var(--table-background)',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child td': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0.5rem',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: { padding: '0.5rem' },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '0.5rem',
                    ':last-child': {
                        paddingBottom: '0.5rem',
                    },
                },
            },
        },
    },
});
