import { useState } from 'react';

type GetStoredKeys<T> = keyof T;

export const useLocalStoredValues = <T,>(
    defaultValue: T,
    storedKeys: GetStoredKeys<T>[],
    storagePrefix: string = '',
) => {
    const getStorageKey = (property: GetStoredKeys<T>) => `${storagePrefix}.stored.${String(property)}`;

    const storeProperty = (newValue: T, property: GetStoredKeys<T>) => {
        try {
            window.localStorage.setItem(getStorageKey(property), JSON.stringify(newValue[property]));
        } catch (err) {
            console.log(err);
        }
    };

    const getStoredProperty = (property: GetStoredKeys<T>) => {
        try {
            const value = window.localStorage.getItem(getStorageKey(property));

            if (value !== undefined && value !== null) return JSON.parse(value);

            return defaultValue[property];
        } catch (err) {
            return defaultValue[property];
        }
    };

    const getStoredObject = () => {
        const value = { ...defaultValue };

        for (const key of storedKeys) value[key] = getStoredProperty(key);

        return value;
    };

    const [value, setStateValue] = useState<T>(getStoredObject());

    const setValue = (newValue: T) => {
        for (const key of storedKeys) storeProperty(newValue, key);

        setStateValue(newValue);
    };

    return [value, setValue] as const;
};
