import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import dextallLogo from 'assets/dextall.svg';
import './Header.css';
import { NewOptimizationTask } from 'containers/NewOptimizationTask';
import { useAuth } from 'utils/useAuth';
import { UserMenu } from './components';

export const Header = () => {
    const { token } = useAuth();

    return (
        <AppBar position='relative'>
            <div className='header__wrapper'>
                <Link component={RouterLink} to='/' sx={{ mr: 4, height: '4rem' }} underline='none'>
                    <img alt='Dextall logo' src={dextallLogo} className='header__dextall-logo' />
                </Link>

                {token && (
                    <Box className='header__menu_bar-wrapper'>
                        <NewOptimizationTask />
                        <UserMenu />
                    </Box>
                )}
            </div>
        </AppBar>
    );
};
