import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLocalStorage } from './useLocalStorage';

const getUserInfoFromToken = (token?: string) => {
    if (!token) {
        return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );
    return JSON.parse(jsonPayload);
};

type UserTokenInfo = { [key: string]: string } | null;

type AuthContextProps = {
    token: string | null;
    userTokenInfo: UserTokenInfo;
    checkIfLoggedIn: () => boolean;
    login: (token: string) => void;
    logout: () => void;
};

const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [token, setToken] = useLocalStorage('token', null);
    const [tokenExpiration, setTokenExpiration] = useLocalStorage('tokenExpiration', null);
    const [userTokenInfo, setUserTokenInfo] = useState<UserTokenInfo>(getUserInfoFromToken(token));
    const navigate = useNavigate();

    const login = (token: string) => {
        const currentTime = new Date().getTime();
        const expirationTimeInMilliseconds = 1000 * 60 * 60 * 24 * 7; // 7 days

        setToken(token);
        setTokenExpiration(currentTime + expirationTimeInMilliseconds);
        setUserTokenInfo(getUserInfoFromToken(token));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        navigate('/');
    };

    const logout = () => {
        setToken(null);
        setTokenExpiration(null);
        setUserTokenInfo(null);
        delete axios.defaults.headers.common['Authorization'];
    };

    const checkIfLoggedIn = (): boolean => token && tokenExpiration && new Date().getTime() < tokenExpiration;

    const value = useMemo(
        () => ({
            token,
            userTokenInfo,
            checkIfLoggedIn,
            login,
            logout,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [token],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};
