import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';
import { useRemoveOptimizationTask } from 'queries/optimizationTasks';
import { OptimizationTask } from 'types';

type Props = {
    optimizationTask: OptimizationTask;
    anchor: Element;
    onClose: () => void;
};

export const RemoveOptimizationTaskDialog = ({ optimizationTask, anchor, onClose }: Props) => {
    const {
        mutateAsync: removeOptimizationTask,
        data: removeOptimizationTaskRequestResults,
        error: removeOptimizationTaskRequestError,
        isPending: isOptimizationTaskRemovalPending,
        isSuccess: isOptimizationTaskRemoved,
        isError: isOptimizationTaskRemovalFailed,
    } = useRemoveOptimizationTask();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isOptimizationTaskRemoved) {
            if (!removeOptimizationTaskRequestResults?.isSuccess && removeOptimizationTaskRequestResults?.message) {
                enqueueSnackbar(removeOptimizationTaskRequestResults?.message, {
                    variant: 'error',
                });

                return;
            }

            if (removeOptimizationTaskRequestResults?.isSuccess) onClose();
        } else if (isOptimizationTaskRemovalFailed) {
            enqueueSnackbar(
                // @ts-expect-error Error is expected because error type is unknown
                removeOptimizationTaskRequestError?.response?.data?.message ||
                    removeOptimizationTaskRequestError?.message ||
                    'Something went wrong',
                {
                    variant: 'error',
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOptimizationTaskRemovalPending]);

    const onConfirmRemoval = async () => await removeOptimizationTask(optimizationTask.id);

    return (
        <Popover
            onClose={onClose}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={true}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box p={2}>
                <Typography mb={2} textAlign='center'>
                    Are you sure you want to delete <b>{optimizationTask.projectName}</b> optimization task?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <LoadingButton
                        onClick={onConfirmRemoval}
                        variant='outlined'
                        loading={isOptimizationTaskRemovalPending}
                    >
                        Yes
                    </LoadingButton>
                    <Button onClick={onClose} variant='outlined'>
                        No
                    </Button>
                </Box>
            </Box>
        </Popover>
    );
};
