import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { BoardSkeleton } from '../BoardSkeleton';
import './RootSkeleton.css';

const RootBoardSkeleton = () => {
    return (
        <Paper className='root-page__board-wrapper-skeleton' sx={{ backgroundColor: 'var(--gray)' }}>
            <Typography className='root-page__board-link-skeleton' variant='h3'>
                <Skeleton width='20%' />
            </Typography>
            <BoardSkeleton />
        </Paper>
    );
};
export const RootSkeleton = () => {
    return (
        <>
            <RootBoardSkeleton />
        </>
    );
};
