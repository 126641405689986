import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ContainerType } from 'types';

type Props = {
    value: string;
    containerTypes: ContainerType[];
    selectContainerType: (containerTypeId: string) => void;
};

export const ContainerTypeSelector = ({ value, containerTypes, selectContainerType }: Props) => {
    return (
        <Box>
            <TextField
                onChange={(event) => selectContainerType(event.target.value)}
                label='Container type'
                value={value}
                fullWidth
                size='small'
                variant='outlined'
                defaultValue={containerTypes[0]?.id}
                select
            >
                {containerTypes.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                        {x.name}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};
