import { FC, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { MainLayout } from 'containers/MainLayout';
import { useAuth } from 'utils/useAuth';

export const ProtectedRoute: FC<{ children: ReactNode }> = ({ children }) => {
    const authContext = useAuth();
    const isLoggedIn = authContext.checkIfLoggedIn();

    useEffect(() => {
        if (!isLoggedIn && authContext.token) {
            authContext.logout();
        }
    });

    if (!isLoggedIn) {
        // redirect to login page if the user is not authenticated
        return <Navigate to='/login' />;
    }

    return <MainLayout>{children}</MainLayout>;
};
