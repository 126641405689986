import { DropzoneOptions, useDropzone } from 'react-dropzone';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import './Dropzone.css';

type Props = {
    options: DropzoneOptions;
    label?: string;
    files?: File[];
    error?: string;
    isLoading?: boolean;
    collapsed?: boolean;
};

export const Dropzone = ({ options, label = 'Click to upload', files, error, isLoading, collapsed }: Props) => {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone(options);

    return (
        <div className='dropzone'>
            <div
                {...getRootProps()}
                className={`dropzone__component 
                          ${collapsed ? 'dropzone__component--collapsed' : ''} 
                          ${isFocused ? 'dropzone__component--focused' : ''} 
                          ${isDragAccept ? 'dropzone__component--accept' : ''} 
                          ${isDragReject ? 'dropzone__component--reject' : ''}`}
            >
                <input {...getInputProps()} />
                {isLoading ? (
                    <CircularProgress size='2rem' />
                ) : (
                    <UploadFileOutlinedIcon className='dropzone__icon' sx={{ width: '2rem', height: '2rem' }} />
                )}
                <Typography variant='subtitle2'>{label}</Typography>
            </div>
            {((files && files.length) || error) && (
                <div className='dropzone__previews'>
                    {error ? (
                        <div className='dropzone__preview'>
                            <UploadFileOutlinedIcon
                                className='dropzone__preview-icon dropzone__preview-icon--error'
                                sx={{ width: '1.5rem', height: '1.5rem' }}
                            />
                            <div className='dropzone__preview-description-wrapper'>
                                <Typography variant='body2' color='var(--red)'>
                                    {error}
                                </Typography>
                            </div>
                        </div>
                    ) : (
                        files?.map((file) => (
                            <div key={file.name} className='dropzone__preview'>
                                <UploadFileOutlinedIcon
                                    className='dropzone__preview-icon'
                                    sx={{ width: '1.5rem', height: '1.5rem' }}
                                />
                                <div title={file.name} className='dropzone__preview-description-main'>
                                    {file.name}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};
