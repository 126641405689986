import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

type Props = {
    value: string;
    setValue: (newValue: string) => void;
    label: string;
    error?: string;
};

export const OptimizationTaskTextField = ({ label, value, error, setValue }: Props) => {
    return (
        <Box>
            <TextField
                onChange={(event) => setValue(event.target.value)}
                label={label}
                value={value}
                error={!!error}
                helperText={error}
                fullWidth
                size='small'
                variant='outlined'
            />
        </Box>
    );
};
