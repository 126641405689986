import { Box, Paper, Typography } from '@mui/material';
import './NonExistingPage.css';

export const NonExistingPage = () => {
    return (
        <Box className='non-existing-page'>
            <Paper className='non-existing-page__message-wrapper'>
                <Typography variant='h1'>The page does not exist</Typography>
            </Paper>
        </Box>
    );
};
