import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { API_PATHS } from 'constants/apiPaths';
import {
    BasicItemResponse,
    BasicItemsResponse,
    BasicResponse,
    CreateOptimizationTaskCommand,
    OptimizationTask,
} from 'types';
import { useAuth } from 'utils/useAuth';

type ResponseType = BasicItemsResponse<OptimizationTask>;

export const useOptimizationTasks = (select?: (data: ResponseType) => ResponseType) => {
    const { token } = useAuth();

    return useQuery<ResponseType>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['optimization-tasks'],

        queryFn: async () => {
            const response = await axios.get<ResponseType>(API_PATHS.optimizationTasks, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        },
        enabled: !!token,
        select: select,
        refetchInterval: (query) => {
            const previousResponse = query.state.data;

            if (!previousResponse?.isSuccess) return undefined;

            const hasUnfinishedJobx = !!previousResponse.items.find(
                (x) => x.status !== 'Completed' && x.status !== 'Optimization failed',
            );

            return hasUnfinishedJobx ? 30000 : undefined;
        },
        refetchOnWindowFocus: true,
    });
};

export const useCreateOptimizationTask = () => {
    const queryClient = useQueryClient();
    const { token } = useAuth();

    return useMutation({
        mutationFn: async (command: CreateOptimizationTaskCommand) => {
            const formData = new FormData();

            formData.append('panelsSequence', command.panelsSequence);
            formData.append('containerTypeId', command.containerTypeId);
            formData.append('projectName', command.projectName);
            formData.append('department', command.department);
            formData.append('projectManager', command.projectManager);
            formData.append('issuedBy', command.issuedBy);
            formData.append('maxBoxesMoves', command.maxBoxesMoves.toString());

            const response = await axios.post<BasicItemResponse<string>>(API_PATHS.createOptimizationTask, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        },

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['optimization-tasks'] });
        },
    });
};

export const useRemoveOptimizationTask = () => {
    const queryClient = useQueryClient();
    const { token } = useAuth();

    return useMutation({
        mutationFn: async (taskId: string) => {
            const response = await axios.delete<BasicResponse>(
                API_PATHS.removeOptimizationTask.replace(':taskId', taskId),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            return response.data;
        },

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['optimization-tasks'] });
        },
    });
};
