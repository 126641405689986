import { useState, MouseEvent } from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useAuth } from 'utils/useAuth';

export const UserMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const authContext = useAuth();

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleCloseMenu();
        authContext.logout();
    };

    return (
        <>
            <IconButton onClick={(event) => handleOpenMenu(event)}>
                <AccountCircleOutlinedIcon color='primary' />
            </IconButton>

            <Menu
                id='menu-user'
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {authContext.userTokenInfo?.name && (
                    <MenuItem component={Typography} sx={{ userSelect: 'inherit' }}>
                        {authContext.userTokenInfo.name}
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout} sx={{ justifyContent: 'center' }}>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
};
