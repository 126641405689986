import { Box, TextField } from '@mui/material';

type Props = {
    value: unknown;
    title: string;
};

export const OptimizationTaskDetailsField = ({ value, title }: Props) => {
    return (
        <Box>
            <TextField
                label={title}
                value={value}
                InputProps={{ readOnly: true }}
                fullWidth
                size='small'
                variant='outlined'
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '& .MuiInputBase-input': {
                            fontWeight: 500,
                        },
                    },
                }}
            />
        </Box>
    );
};
