import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import './TitledSection.css';

type Props = {
    title: string;
    children: ReactNode;
};

export const TitledSection = ({ title, children }: Props) => {
    return (
        <Box className='titled-section'>
            <div className='titled-section__wrapper-header'>
                <div className='headerBorderBefore'></div>
                <div className='headerTitle'>{title}</div>
                <div className='headerBorderAfter'></div>
            </div>
            {children}
        </Box>
    );
};
