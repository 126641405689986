import { useState } from 'react';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { useContainerTypes } from 'queries/containerTypes';
import { isSuccessfullResponse } from 'utils/common';
import { NewOptimizationTaskDialog } from './NewOptimizationTaskDialog';

export const NewOptimizationTask = () => {
    const containerTypes = useContainerTypes();
    const [isOpened, setIsOpened] = useState(false);
    const handleClickOpen = () => setIsOpened(true);

    return (
        <>
            {containerTypes.isLoading && <Skeleton width='12rem' />}
            {isSuccessfullResponse(containerTypes) && (
                <Button onClick={handleClickOpen} sx={{ fontSize: '1.25rem', textWrap: 'nowrap' }}>
                    <SettingsSuggestIcon color='primary' />
                    &nbsp;New optimization
                </Button>
            )}

            {isOpened && (
                <NewOptimizationTaskDialog containerTypes={containerTypes.data!.items!} setDialogOpened={setIsOpened} />
            )}
        </>
    );
};
