import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { unknownFrontErrorMessage } from 'constants/messages';
import { BasicResponse } from 'types';
import './ErrorContainer.css';

export const ErrorContainer = ({ data, error }: { data?: BasicResponse; error: Error | null }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        const errorMessage = error instanceof AxiosError ? error?.message : JSON.stringify(error);
        // @ts-expect-error Error is expected because error type is unknown
        setErrorMessage(error?.response?.data?.message || (error && errorMessage) || data?.message || '');
    }, [data?.message, error]);

    return (
        <Paper className='error-container'>
            <Typography variant='subtitle1' color='var(--red)'>
                {unknownFrontErrorMessage}
            </Typography>
            {errorMessage && (
                <Typography variant='body1' color='var(--red)'>
                    {errorMessage}
                </Typography>
            )}
        </Paper>
    );
};
