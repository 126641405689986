import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BasicResponse } from 'types';

export const getErrorMessage = ({ data, error }: { data?: unknown; error: Error | null }) => {
    const networkErrorMessage = error instanceof AxiosError ? error?.message : JSON.stringify(error);
    // @ts-expect-error Disable error type check to get response
    return data?.message || error?.response?.data?.message || (error && networkErrorMessage);
};

export const cx = (...list: (string | boolean | undefined | null)[]): string => list.filter(Boolean).join(' ');

export const isFailureResponse = (response: UseQueryResult<BasicResponse>): boolean => {
    const { data, isLoading, isSuccess } = response;

    return !isLoading && (!isSuccess || !data.isSuccess);
};

export const isSuccessfullResponse = (response: UseQueryResult<BasicResponse>): boolean => {
    const { data, isLoading, isSuccess } = response;

    return !isLoading && isSuccess && data.isSuccess;
};

export const toSafeFileName = (unsafeFileName: string): string => unsafeFileName.replace(/[/\\?%*:|"<>]/g, '-');
