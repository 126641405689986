import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const TableCellSkeleton = () => <Skeleton width='25%' sx={{ m: '0.25rem' }} />;

const TableRowSkeleton = () => (
    <Box sx={{ display: 'flex' }}>
        {Array.from(Array(10).keys()).map((item) => (
            <TableCellSkeleton key={item} />
        ))}
    </Box>
);

const TableSkeleton = () => (
    <Paper
        elevation={1}
        variant='elevation'
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            width: '100%',
            p: 1,
            backgroundColor: 'transparent',
        }}
    >
        <Skeleton height='1.5rem' variant='rounded' width='10rem' sx={{ my: 1 }} />
        <Box
            sx={{
                width: '100%',
                overflowX: 'auto',
                borderRadius: '0.25rem',
                border: '1px solid lightgray',
            }}
        >
            <TableRowSkeleton />
            <TableRowSkeleton />
            <TableRowSkeleton />
        </Box>
    </Paper>
);

export const BoardSkeleton = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
            <TableSkeleton />
            <TableSkeleton />
        </Box>
    );
};
