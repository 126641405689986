import Box from '@mui/material/Box';
import { ErrorContainer } from 'components/ErrorContainer';
import { RootSkeleton } from 'components/RootSkeleton';
import { OptimizationTasksBoard } from 'containers/OptimizationTasksBoard/OptimizationTasksBoard';
import { useContainerTypes } from 'queries/containerTypes';
import { useOptimizationTasks } from 'queries/optimizationTasks';
import { isFailureResponse } from 'utils/common';
import './RootPage.css';

export const RootPage = () => {
    const containerTypes = useContainerTypes();
    const optimizationTasks = useOptimizationTasks();

    const failedToLoadData = isFailureResponse(containerTypes) || isFailureResponse(optimizationTasks);
    const wrapperClassName = failedToLoadData
        ? 'root-page__optimization-tasks-wrapper failure'
        : 'root-page__optimization-tasks-wrapper';

    return (
        <Box className='root-page'>
            <Box className={wrapperClassName}>
                {containerTypes.isLoading || optimizationTasks.isLoading ? (
                    <RootSkeleton />
                ) : failedToLoadData ? (
                    <ErrorContainer
                        data={isFailureResponse(containerTypes) ? containerTypes.data : optimizationTasks.data}
                        error={isFailureResponse(containerTypes) ? containerTypes.error : optimizationTasks.error}
                    />
                ) : (
                    <OptimizationTasksBoard
                        containerTypes={containerTypes.data?.items || []}
                        optimizationTasks={optimizationTasks.data?.items || []}
                    />
                )}
            </Box>
        </Box>
    );
};
