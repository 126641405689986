import { FC, ReactNode } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { SnackbarOrigin } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SnackbarProvider, closeSnackbar } from 'notistack';

const MAX_SNACK = 3;
const AUTO_HIDE_DURATION = 10000;
const POSITION: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

export const Snackbar: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <SnackbarProvider
            action={(snackbarId) => (
                <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label='Close notification' size='small'>
                    <CloseOutlinedIcon />
                </IconButton>
            )}
            anchorOrigin={POSITION}
            autoHideDuration={AUTO_HIDE_DURATION}
            maxSnack={MAX_SNACK}
        >
            {children}
        </SnackbarProvider>
    );
};
