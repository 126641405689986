import { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { Dropzone } from 'components/Dropzone';
import { useCreateOptimizationTask } from 'queries/optimizationTasks';
import { ContainerType, CreateOptimizationTaskCommand } from 'types';
import { useLocalStoredValues } from 'utils/useLocalStoredValues';
import { ContainerTypeSelector } from './components/ContainerTypeSelector';
import { OptimizationTaskMaxBoxesMovesSelector } from './components/OptimizationTaskMaxBoxesMovesSelector';
import { OptimizationTaskTextField } from './components/OptimizationTaskTextField';
import './NewOptimizationTaskDialog.css';

type Props = {
    containerTypes: ContainerType[];
    setDialogOpened: (isOpened: boolean) => void;
};

type OptimizationTaskProperties = Omit<CreateOptimizationTaskCommand, 'panelsSequence'>;

const defaultOptimizationTaskProperties: OptimizationTaskProperties = {
    containerTypeId: '',
    projectName: '',
    department: '',
    projectManager: '',
    issuedBy: '',
    maxBoxesMoves: 2,
};

export const NewOptimizationTaskDialog = ({ containerTypes, setDialogOpened }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const sourceFile = useRef<File>();
    const [sourceFileName, setSourceFileName] = useState('');
    const [showInvalidProjectNameError, setShowInvalidProjectNameError] = useState(false);
    const [showMissingSourceFileError, setShowMissingSourceFileError] = useState(false);
    const [properties, setProperties] = useLocalStoredValues<OptimizationTaskProperties>(
        { ...defaultOptimizationTaskProperties, containerTypeId: containerTypes[0].id },
        ['department', 'issuedBy', 'projectManager', 'maxBoxesMoves', 'containerTypeId'],
        'optimization-task-defaults',
    );

    const {
        mutateAsync: createOptimizationTask,
        data: optimizationTaskCreationResult,
        error: optimizationTaskCreationError,
        isPending: isOptimizationTaskCreationPending,
        isSuccess: isOptimizationTaskCreated,
        isError: isOptimizationTaskCreationFailed,
    } = useCreateOptimizationTask();

    const navigate = useNavigate();

    useEffect(() => {
        if (isOptimizationTaskCreated) {
            if (!optimizationTaskCreationResult?.isSuccess && optimizationTaskCreationResult?.message) {
                enqueueSnackbar(optimizationTaskCreationResult?.message, {
                    variant: 'error',
                });

                return;
            }

            if (optimizationTaskCreationResult?.isSuccess) {
                closeDialog();
                navigate(`/optimization-tasks/${optimizationTaskCreationResult.item}`);
            }
        } else if (isOptimizationTaskCreationFailed) {
            enqueueSnackbar(
                // @ts-expect-error Error is expected because error type is unknown
                optimizationTaskCreationError?.response?.data?.message ||
                    optimizationTaskCreationError?.message ||
                    'Something went wrong',
                {
                    variant: 'error',
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOptimizationTaskCreationPending]);

    const onDropOptimizationSourceExcelFile: (acceptedFiles: File[], fileRejections: FileRejection[]) => void =
        useCallback(
            (acceptedFiles, fileRejections) => {
                setShowMissingSourceFileError(false);
                if (fileRejections.length > 1) {
                    enqueueSnackbar('Please add only one file', { variant: 'error' });

                    return;
                }

                if (
                    fileRejections.length === 1 &&
                    fileRejections[0].errors.find((x) => x.code === 'file-invalid-type')
                ) {
                    enqueueSnackbar('Only XLSX source files are supported', { variant: 'error' });

                    return;
                }

                if (acceptedFiles.length !== 1) return;

                sourceFile.current = acceptedFiles[0];
                setSourceFileName(acceptedFiles[0].name);
            },
            [enqueueSnackbar],
        );

    const validateProjectName = (name: string) => !!name.trim();

    const setProjectName = (projectName: string) => {
        setProperties({ ...properties, projectName });
        setShowInvalidProjectNameError(!validateProjectName(projectName));
    };

    const options = {
        onDrop: onDropOptimizationSourceExcelFile,
        multiple: false,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
    };

    const closeDialog = () => {
        setDialogOpened(false);
        setProjectName('');
        sourceFile.current = undefined;
        setSourceFileName('');
        setShowInvalidProjectNameError(false);
        setShowMissingSourceFileError(false);
    };

    const handleCreateOptimizationTask: FormEventHandler = async (event) => {
        event.preventDefault();

        if (!sourceFile.current) {
            setShowMissingSourceFileError(true);

            return;
        }

        if (!validateProjectName(properties.projectName)) {
            setShowInvalidProjectNameError(true);

            return;
        }

        const command: CreateOptimizationTaskCommand = { ...properties, panelsSequence: sourceFile.current! };

        await createOptimizationTask(command);
    };

    return (
        <Dialog onClose={closeDialog} fullWidth open>
            <DialogTitle>New optimization task</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <Dropzone
                        label={sourceFileName ? sourceFileName : 'Pick optimization task source XLSX file'}
                        error={
                            showMissingSourceFileError
                                ? 'Please pick the Excel file with panels sequence for optimization'
                                : undefined
                        }
                        options={options}
                    />
                </Box>
                <div className='new-optimization-task-dialog-fields'>
                    <div className='new-optimization-task-dialog-fields__fields-wrapper'>
                        <OptimizationTaskTextField
                            label='Project name'
                            value={properties.projectName}
                            error={showInvalidProjectNameError ? "Project name can't be empty" : ''}
                            setValue={setProjectName}
                        />

                        <ContainerTypeSelector
                            value={properties.containerTypeId}
                            containerTypes={containerTypes}
                            selectContainerType={(containerTypeId) => setProperties({ ...properties, containerTypeId })}
                        />

                        <OptimizationTaskMaxBoxesMovesSelector
                            value={properties.maxBoxesMoves}
                            setValue={(maxBoxesMoves) => setProperties({ ...properties, maxBoxesMoves })}
                        />

                        <OptimizationTaskTextField
                            label='Department'
                            value={properties.department}
                            setValue={(department) => setProperties({ ...properties, department })}
                        />

                        <OptimizationTaskTextField
                            label='Project manager'
                            value={properties.projectManager}
                            setValue={(projectManager) => setProperties({ ...properties, projectManager })}
                        />

                        <OptimizationTaskTextField
                            label='Issued by'
                            value={properties.issuedBy}
                            setValue={(issuedBy) => setProperties({ ...properties, issuedBy })}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <LoadingButton
                    onClick={handleCreateOptimizationTask}
                    type='submit'
                    variant='contained'
                    endIcon={<DoneIcon />}
                    loading={isOptimizationTaskCreationPending}
                    loadingPosition='end'
                >
                    <span>Create optimization task</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
