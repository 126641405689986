import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Header } from 'containers/Header';
import { Snackbar } from 'containers/Snackbar';
import { getLocalApplicationMetadata } from 'utils/appMetadata';
import './MainLayout.css';

const Copyright = () => {
    const applicationMetadata = getLocalApplicationMetadata();

    const applicationVersion =
        applicationMetadata.environment === 'development' ? 'development' : applicationMetadata.version;

    return (
        <Typography align='right' fontSize='0.8rem' marginRight='1rem'>
            Copyright 2024 © Dextall, inc. Version: {applicationVersion}
        </Typography>
    );
};

export const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Snackbar>
            <Header />
            <Stack className='main-layout__wrapper'>
                <Container component='main' className='main-layout__main' maxWidth={false} disableGutters>
                    {children}
                </Container>
            </Stack>
            <Box component='footer' className='main-layout__footer'>
                <Copyright />
            </Box>
        </Snackbar>
    );
};
