import axios, { AxiosError } from 'axios';
import { API_PATHS } from 'constants/apiPaths';
import { unknownServerErrorMessage } from 'constants/messages';
import { BasicItemResponse } from 'types';

export type OptimizationResultType = 'optimizationTaskPackingSchema' | 'optimizationTaskLogs';

export const createOptimizationTaskResultsDownloadUrl = async (
    taskId: string,
    token: string,
    resultType: OptimizationResultType,
): Promise<BasicItemResponse<string>> => {
    const endPoint = API_PATHS[resultType];

    if (!endPoint) throw new Error('Invalid endpoint!');

    try {
        const response = await axios.post<BasicItemResponse<string>>(
            endPoint.replace(':taskId', taskId),
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return response.data;
    } catch (error) {
        if (error instanceof AxiosError && error.response?.data?.message) return error.response.data;

        return { isSuccess: false, message: unknownServerErrorMessage, item: null };
    }
};
