import { useRef, useState } from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { OptimizationTask } from 'types';
import { toSafeFileName } from 'utils/common';
import { enqueueSnackbar } from 'notistack';

type Props = {
    optimizationTask: OptimizationTask;
};

export const OptimizationTaskExcelResultsDownload = ({ optimizationTask }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    if (optimizationTask.status !== 'Optimized' && optimizationTask.status !== 'Completed') return null;

    const loadTaskResults = async () => {
        const downloadLinkElement = downloadLinkRef.current!;

        setIsLoading(true);

        const download = async () => {
            try {
                return await axios.get<ArrayBuffer>(`/api/optimization-tasks/${optimizationTask.id}/worksheet`, {
                    responseType: 'arraybuffer',
                });
            } catch (e) {
                return null;
            }
        };

        const contentResponse = await download();

        if (contentResponse === null) {
            setIsLoading(false);

            enqueueSnackbar('Failed to download optimization results', { variant: 'error' });

            return;
        }

        const blob = new Blob([contentResponse.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const url = window.URL.createObjectURL(blob);

        downloadLinkElement.href = url;

        downloadLinkElement.click();

        window.URL.revokeObjectURL(url);

        setIsLoading(false);
    };

    return (
        <>
            <LoadingButton
                onClick={loadTaskResults}
                size='small'
                variant='outlined'
                loading={isLoading}
                loadingPosition='start'
                startIcon={<ListAltIcon />}
            >
                Containers (XLSX)
            </LoadingButton>
            <a
                ref={downloadLinkRef}
                style={{ display: 'none' }}
                download={`${toSafeFileName(optimizationTask.projectName)}.xlsx`}
            />
        </>
    );
};
