export const API_PATHS = {
    login: '/api/login',
    containerTypes: '/api/container-types',
    optimizationTasks: '/api/optimization-tasks',
    optimizationTask: '/api/optimization-tasks/:taskId',
    optimizationTaskPanels: '/api/optimization-tasks/:taskId/panels',
    createOptimizationTask: '/api/optimization-tasks/create',
    optimizationTaskPackingSchema: '/api/optimization-tasks/:taskId/packing-schema/download',
    optimizationTaskLogs: '/api/optimization-tasks/:taskId/logs',
    removeOptimizationTask: '/api/optimization-tasks/:taskId/remove',
    metadata: '/metadata.json',
};
