import { Box, Paper } from '@mui/material';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { RootSkeleton } from 'components/RootSkeleton';
import { OptimizationTaskWithPanels } from 'types';
import './OptimizationTaskPanelsList.css';

type Props = { optimizationTask: OptimizationTaskWithPanels | null | undefined; isLoading: boolean };

export const OptimizationTaskPanelsList = ({ isLoading, optimizationTask }: Props) => {
    const table = useReactTable({
        data: optimizationTask?.panels || [],
        columns: [
            {
                accessorFn: (x) => x.index,
                id: 'index',
                header: 'No.',
            },
            {
                accessorFn: (x) => x.panelName,
                id: 'panelName',
                header: 'Panel name',
            },
            {
                accessorFn: (x) => x.panelId,
                id: 'panelId',
                header: 'Panel id',
            },
            {
                accessorFn: (x) => x.type,
                id: 'type',
                header: 'Type',
            },
            {
                accessorFn: (x) => x.crateIndex,
                id: 'crate',
                header: 'Crate No.',
            },
            {
                accessorFn: (x) => x.containerIndex,
                id: 'container',
                header: 'Container',
            },
        ],
        getCoreRowModel: getCoreRowModel(),
    });

    if (isLoading || !optimizationTask) return <RootSkeleton />;

    return (
        <Paper>
            <TableContainer className='optimization-task-panels-list-table'>
                <Box className='optimization-task-panels-list-table__table-wrapper'>
                    <MuiTable stickyHeader>
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <TableCell
                                                key={header.id}
                                                className='optimization-task-panels-list-table__header-cell'
                                                colSpan={header.colSpan}
                                            >
                                                <div className='optimization-task-panels-list-table__header-cell-wrapper'>
                                                    <div className='optimization-task-panels-list-table__header-column-name-wrapper'>
                                                        <Typography
                                                            className='optimization-task-panels-list-table__header-column-name'
                                                            variant='body1'
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext(),
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <TableCell
                                                key={cell.id}
                                                className='optimization-task-panels-list-table__row-cell'
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </MuiTable>
                </Box>
            </TableContainer>
        </Paper>
    );
};
