import { useRef, useState } from 'react';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {
    createOptimizationTaskResultsDownloadUrl,
    OptimizationResultType,
} from 'queries/optimizationResultsDownloadUtils';
import { OptimizationTask } from 'types';
import { toSafeFileName } from 'utils/common';
import { useAuth } from 'utils/useAuth';

type Props = {
    optimizationTask: OptimizationTask;
    resultType: OptimizationResultType;
};

export const OptimizationTaskResultsDownload = ({ optimizationTask, resultType }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    const loadTaskResults = async () => {
        const downloadLinkElement = downloadLinkRef.current;

        if (token === null || downloadLinkElement === null) return;

        setIsLoading(true);

        const downloadUrlResponse = await createOptimizationTaskResultsDownloadUrl(
            optimizationTask.id,
            token,
            resultType,
        );

        if (downloadUrlResponse.isSuccess) {
            if (resultType === 'optimizationTaskPackingSchema') {
                const download = async () => {
                    try {
                        return await axios.get<ArrayBuffer>(downloadUrlResponse.item, {
                            responseType: 'arraybuffer',
                        });
                    } catch (e) {
                        return null;
                    }
                };

                const contentResponse = await download();

                if (contentResponse === null) {
                    enqueueSnackbar('Failed to download packing schema', { variant: 'error' });

                    setIsLoading(false);

                    return;
                }

                const blob = new Blob([contentResponse.data]);

                const url = window.URL.createObjectURL(blob);

                downloadLinkElement.href = url;

                downloadLinkElement.click();

                window.URL.revokeObjectURL(url);
            } else {
                downloadLinkElement.href = downloadUrlResponse.item;

                downloadLinkElement.click();
            }
        } else enqueueSnackbar(downloadUrlResponse.message, { variant: 'error' });

        setIsLoading(false);
    };

    return (
        <>
            <LoadingButton
                onClick={loadTaskResults}
                size='small'
                variant={resultType === 'optimizationTaskPackingSchema' ? 'contained' : 'outlined'}
                loading={isLoading}
                loadingPosition='start'
                startIcon={
                    <>
                        {resultType === 'optimizationTaskPackingSchema' && <PrecisionManufacturingIcon />}
                        {resultType === 'optimizationTaskLogs' && <EventNoteIcon />}
                    </>
                }
            >
                {resultType === 'optimizationTaskPackingSchema' && 'Packing schema'}
                {resultType === 'optimizationTaskLogs' && 'Logs'}
            </LoadingButton>
            <a
                ref={downloadLinkRef}
                style={{ display: 'none' }}
                download={
                    resultType === 'optimizationTaskPackingSchema'
                        ? `${toSafeFileName(optimizationTask.projectName)}.dwg`
                        : undefined
                }
                target={resultType === 'optimizationTaskLogs' ? '_blank' : undefined}
            />
        </>
    );
};
