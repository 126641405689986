import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'containers/ProtectedRoute';
import { LoginPage } from 'pages/LoginPage';
import { NonExistingPage } from 'pages/NonExistingPage';
import { OptimizationTaskPage } from 'pages/OptimizationTaskPage';
import { RootPage } from 'pages/RootPage';
import { validateApplicationVersion } from 'utils/appMetadata';

export const App = () => {
    useEffect(() => {
        validateApplicationVersion();
    }, []);

    return (
        <Routes>
            <Route
                element={
                    <ProtectedRoute>
                        <RootPage />
                    </ProtectedRoute>
                }
                path='/'
            />

            <Route
                element={
                    <ProtectedRoute>
                        <OptimizationTaskPage />
                    </ProtectedRoute>
                }
                path='/optimization-tasks/:taskId'
            />

            <Route element={<LoginPage />} path='login' />

            <Route
                element={
                    <ProtectedRoute>
                        <NonExistingPage />
                    </ProtectedRoute>
                }
                path='*'
            />
        </Routes>
    );
};
