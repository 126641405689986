import axios from 'axios';
import { API_PATHS } from 'constants/apiPaths';

export const getLocalApplicationMetadata = () => {
    const appRoot = document.getElementById('root')!;

    const environment = appRoot.attributes.getNamedItem('data-app-environment')!.value;

    const version = appRoot.attributes.getNamedItem('data-app-version')!.value;

    return { environment, version };
};

export const validateApplicationVersion = async () => {
    const localMetadata = getLocalApplicationMetadata();

    if (localMetadata.environment === 'development') return;

    type ServerMetadata = { version: string };

    try {
        const appMetadataResponse = await axios.get<ServerMetadata>(API_PATHS.metadata);

        if (appMetadataResponse.data.version === localMetadata.version) return;

        if (caches) {
            const keys = await caches.keys();

            for (const key of keys) caches.delete(key);
        }

        window.location.search = `?r=${Date.now().toString()}`;
    } catch (error) {
        console.error(error);
    }
};
