import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_PATHS } from 'constants/apiPaths';
import {
    BasicItemResponse,
    BasicItemsResponse,
    ContainerType,
    OptimizationTaskPanel,
    OptimizationTaskStatus,
    OptimizationTaskWithPanels,
} from 'types';
import { useAuth } from 'utils/useAuth';

type ResponseType = BasicItemResponse<OptimizationTaskWithPanels>;

export const useOptimizationTask = (select?: (data: ResponseType) => ResponseType) => {
    const { token } = useAuth();
    const params = useParams();
    const taskId = params.taskId || '';

    return useQuery<ResponseType>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['optimization-task', taskId],

        queryFn: async () => {
            const taskResponse = await axios.get<ResponseType>(API_PATHS.optimizationTask.replace(':taskId', taskId), {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!taskResponse.data.isSuccess) return taskResponse.data;

            const optimizationTask = taskResponse.data.item;

            const panelsResponse = await axios.get<BasicItemsResponse<OptimizationTaskPanel>>(
                API_PATHS.optimizationTaskPanels.replace(':taskId', taskId),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            if (!panelsResponse.data.isSuccess) return { ...panelsResponse.data, item: null };

            optimizationTask.panels = panelsResponse.data.items;

            const containersResponse = await axios.get<BasicItemsResponse<ContainerType>>(API_PATHS.containerTypes, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!containersResponse.data.isSuccess) return { ...containersResponse.data, item: null };

            optimizationTask.containerType = containersResponse.data.items.find(
                (x) => x.id === optimizationTask.containerTypeId,
            )!;

            return { isSuccess: true, item: optimizationTask, message: null };
        },
        enabled: !!token && !!taskId,
        select: select,
        refetchInterval: (query) => {
            const previousResponse = query.state.data;

            if (!previousResponse?.isSuccess) return 10000;

            return refetchIntervals[previousResponse.item.status];
        },
    });
};

type RefetchIntervals = {
    [key in OptimizationTaskStatus]?: number;
};

const refetchIntervals: RefetchIntervals = {
    Created: 100,
    Queued: 700,
    'In progress': 30000,
    Optimized: 1000,
};
