import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App } from 'containers/App';
import { getLocalApplicationMetadata } from 'utils/appMetadata';
import { AuthProvider } from 'utils/useAuth';
import { lightTheme } from './constants/theme';
import './main.css';

const appMetadata = getLocalApplicationMetadata();

if (appMetadata.environment === 'production')
    Sentry.init({
        dsn: 'https://4a6215e293dc9445c0cf7a896c67eea8@o4506003617808384.ingest.us.sentry.io/4507577911017472',
        environment: 'webapp',
        defaultIntegrations: false,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: `dextall-packing-optimizer@${appMetadata.version}`,
        integrations: [
            Sentry.breadcrumbsIntegration({ console: false }),
            Sentry.globalHandlersIntegration(),
            Sentry.dedupeIntegration(),
            Sentry.functionToStringIntegration(),
            Sentry.replayIntegration({ mutationLimit: 500 }),
            Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
        ],
    });

const queryClient = new QueryClient({
    defaultOptions: {
        // TODO: configure to our needs for keeping the data fresh enough
        queries: { refetchOnWindowFocus: false, retry: false, staleTime: Infinity },
    },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <ThemeProvider theme={lightTheme}>
                        <CssBaseline />
                        {/* <ErrorHandler /> */}
                        <App />
                    </ThemeProvider>
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
