import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { API_PATHS } from 'constants/apiPaths';
import { AuthentificateCommand, BasicItemResponse } from 'types';

export const useLogin = () => {
    return useMutation({
        mutationFn: async (userData: AuthentificateCommand): Promise<BasicItemResponse<string>> => {
            try {
                const response = await axios.post<BasicItemResponse<string>>(API_PATHS.login, userData);

                return response.data;
            } catch (e) {
                if (e instanceof AxiosError && e.response?.data?.message)
                    return e.response.data;

                return { isSuccess: false, message: "Server error. Repeat operation later", item: null }
            }
        },
    });
};
