import { FormEventHandler, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { emailRegex } from 'constants/common';
import { useLogin } from 'queries/login';
import { useAuth } from 'utils/useAuth';
import './LoginPage.css';

export const LoginPage = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorText, setErrorText] = useState('');
    const authContext = useAuth();

    const { mutateAsync: mutateLogin, data, error, isPending, isSuccess, isError } = useLogin();

    const handleLogin: FormEventHandler = async (event) => {
        event.preventDefault();

        if (!username) {
            setErrorText('Please enter an email');
            return;
        }

        if (!password?.trim()) {
            setErrorText('Please enter a password');
            return;
        }

        if (!emailRegex.test(username)) {
            setErrorText('Please enter a valid email');
            return;
        }

        setErrorText('');
        await mutateLogin({ name: username, password });
    };

    useEffect(() => {
        const token = data?.item;

        // TODO: display error from backend
        if (isSuccess && token) {
            authContext?.login(token);
        } else if (data?.message) {
            setErrorText(data?.message);
        } else if (isError) {
            setErrorText(error?.message || 'Something went wrong');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPending]);

    if (authContext?.token) {
        // redirect to root page if the user is authenticated
        return <Navigate to='/' />;
    }

    return (
        <div className='login-form-container'>
            <Paper>
                <Box component='form' onSubmit={handleLogin} autoComplete='on' className='login-form' noValidate>
                    <Typography className='title' mb='1rem' variant='h4'>
                        Welcome to Dextall packing optimizer
                    </Typography>

                    <TextField
                        id='username'
                        onChange={(event) => setUsername(event.target.value.trim())}
                        label='Email'
                        name='email'
                        required
                        type='email'
                        value={username}
                        autoComplete='email'
                        autoFocus
                        fullWidth
                        variant='outlined'
                        sx={{ mb: '1rem' }}
                    />

                    <TextField
                        id='password'
                        onChange={(event) => setPassword(event.target.value)}
                        label='Password'
                        name='password'
                        required
                        type='password'
                        value={password}
                        autoComplete='password'
                        fullWidth
                        variant='outlined'
                        sx={{ mb: '0.5rem' }}
                    />

                    <Box className='login-error-message-container'>
                        <Typography className='login-error-message' variant='subtitle1'>
                            {errorText}
                        </Typography>
                    </Box>

                    <LoadingButton
                        type='submit'
                        fullWidth
                        variant='contained'
                        endIcon={<LoginIcon />}
                        loading={isPending}
                        loadingPosition='end'
                    >
                        <span>Log in</span>
                    </LoadingButton>
                </Box>
            </Paper>
        </div>
    );
};
