import Skeleton from '@mui/material/Skeleton';

export const OptimizationTaskDetailsSkeleton = () => {
    return (
        <>
            {Array.from(Array(6).keys()).map((item) => (
                <Skeleton key={item} height='2.5rem' sx={{ m: '1rem' }} />
            ))}
        </>
    );
};
