import { Paper } from '@mui/material';
import { StatusBadge } from 'components/StatusBadge';
import { OptimizationTaskWithPanels } from 'types';
import { OptimizationTaskDetailsField } from './components/OptimizationTaskDetailsField';
import { OptimizationTaskDetailsSkeleton } from './components/OptimizationTaskDetailsSkeleton';
import { OptimizationTaskResults } from './components/OptimizationTaskResults';
import './OptimizationTaskDetails.css';

type Props = { optimizationTask: OptimizationTaskWithPanels | null | undefined; isLoading: boolean };

export const OptimizationTaskDetails = ({ isLoading, optimizationTask }: Props) => {
    return (
        <Paper className='optimization-task-details'>
            <div className='optimization-task-details__status-badge-wrapper'>
                {optimizationTask && <StatusBadge status={optimizationTask.status} />}
            </div>
            <div className='optimization-task-details__fields-wrapper'>
                {!isLoading && optimizationTask && (
                    <>
                        <OptimizationTaskDetailsField title='Project name' value={optimizationTask.projectName} />
                        <OptimizationTaskDetailsField
                            title='Container type'
                            value={optimizationTask.containerType.name}
                        />
                        <OptimizationTaskDetailsField title='Department' value={optimizationTask.department || ' '} />
                        <OptimizationTaskDetailsField
                            title='Project manager'
                            value={optimizationTask.projectManager || ' '}
                        />
                        <OptimizationTaskDetailsField title='issuedBy' value={optimizationTask.issuedBy || ' '} />
                        <OptimizationTaskDetailsField
                            title='Max boxes moved between containers'
                            value={optimizationTask.maxBoxesMoves}
                        />
                        <OptimizationTaskDetailsField title='Panels count' value={optimizationTask.panelsCount} />
                        <OptimizationTaskDetailsField
                            title='Containers count'
                            value={optimizationTask.containersCount || ' '}
                        />
                    </>
                )}
                {(isLoading || !optimizationTask) && <OptimizationTaskDetailsSkeleton />}
            </div>
            <div className='optimization-task-details__download-controls'>
                {optimizationTask && <OptimizationTaskResults optimizationTask={optimizationTask} />}
            </div>
        </Paper>
    );
};
