import { OptimizationTaskStatus } from 'types';
import './StatusBadge.css';

type Props = {
    status: OptimizationTaskStatus;
};

export const StatusBadge = ({ status }: Props) => {
    const color = badgeColors[status];

    return (
        <button className='status-badge'>
            <span className='status-badge-span' style={color}>
                {status}
            </span>
        </button>
    );
};

type StatusBadgeColor = {
    color: string;
    backgroundColor: string;
};

type StatusBadgeColors = {
    [key in OptimizationTaskStatus]: StatusBadgeColor;
};

const inProgressBadgeColor: StatusBadgeColor = { color: '#0747A6', backgroundColor: '#DEEBFF' };
const firstStageColor: StatusBadgeColor = { color: '#42526E', backgroundColor: '#DFE1E6' };

const badgeColors: StatusBadgeColors = {
    'Optimization failed': { color: '#AE2E24', backgroundColor: '#FFECEB' },
    Completed: { color: '#006644', backgroundColor: '#E3FCEF' },
    Created: firstStageColor,
    Queued: firstStageColor,
    'In progress': inProgressBadgeColor,
    Optimized: inProgressBadgeColor,
};
