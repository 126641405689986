import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { OptimizationTaskMaxBoxesMoves } from 'types';

type Props = {
    value: OptimizationTaskMaxBoxesMoves;
    setValue: (newValue: OptimizationTaskMaxBoxesMoves) => void;
};

export const OptimizationTaskMaxBoxesMovesSelector = ({ value, setValue }: Props) => {
    return (
        <Box>
            <TextField
                onChange={(event) => setValue(parseInt(event.target.value) as OptimizationTaskMaxBoxesMoves)}
                label='Max boxes moved between containers'
                value={value.toString()}
                fullWidth
                size='small'
                variant='outlined'
                defaultValue={'2'}
                select
            >
                <MenuItem key={'0'} value={'0'}>
                    0
                </MenuItem>
                <MenuItem key={'2'} value={'2'}>
                    2
                </MenuItem>
            </TextField>
        </Box>
    );
};
