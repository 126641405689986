import { BasicItemResponse } from '../types';
import { unknownFrontErrorMessage } from './messages';

export const emailRegex = /^([\w\-.]+)@([\w.]+)\.(\w+)$/;

export const basicItemResponseSuccess: BasicItemResponse<unknown> = {
    isSuccess: true,
    message: null,
    item: {},
};

export const basicItemResponseError: BasicItemResponse<unknown> = {
    isSuccess: false,
    message: unknownFrontErrorMessage,
    item: null,
};
