import Box from '@mui/material/Box';
import { TitledSection } from 'components/TitledSection';
import { OptimizationTask } from 'types';
import { OptimizationTaskExcelResultsDownload } from './components/OptimizationTaskExcelResultsDownload';
import { OptimizationTaskResultsDownload } from './components/OptimizationTaskResultsDownload';
import '../../OptimizationTaskDetails.css';

type Props = {
    optimizationTask: OptimizationTask;
};

export const OptimizationTaskResults = ({ optimizationTask }: Props) => {
    const status = optimizationTask.status;
    const hasGeneratedContainers = status === 'Optimized' || status === 'Completed';
    const hasGeneratedPackingSchema = status === 'Completed';
    const hasLogs = hasGeneratedContainers || status === 'Optimization failed';

    if (!(hasGeneratedContainers || hasGeneratedPackingSchema || hasLogs)) return null;

    return (
        <Box className='optimization-task-details__download-controls-wrapper'>
            <TitledSection title='Optimization results'>
                <div className='optimization-task-details__download-controls-content'>
                    {hasGeneratedContainers && (
                        <OptimizationTaskExcelResultsDownload optimizationTask={optimizationTask} />
                    )}
                    {hasGeneratedPackingSchema && (
                        <OptimizationTaskResultsDownload
                            optimizationTask={optimizationTask}
                            resultType='optimizationTaskPackingSchema'
                        />
                    )}
                    {hasLogs && (
                        <OptimizationTaskResultsDownload
                            optimizationTask={optimizationTask}
                            resultType='optimizationTaskLogs'
                        />
                    )}
                </div>
            </TitledSection>
        </Box>
    );
};
